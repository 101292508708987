var url = window["config"]
  ? window["config"].url
  : "https://api.instacommcloud.de/";

export const environment = {
  production: true,
  envName: "prod",
  local: false,
  apiUrl: "https://api.instacommcloud.de/",
  cdnUrl: "https://api.instacommcloud.de/",
  baseImagePath: "https://api.instacommcloud.de/"
};
